<template>
  <div class="body">
    <div class="title"><span>扫码次数</span> <span>扫码详情</span></div>
    <div>
      <div class="list" v-for="(item,index) in list" :key="index">
        <div class="index">{{index+1}}</div>
        <div style="margin:10px">{{item.scanDate}}</div>
        <div style="margin:20px">{{item.scanAddr}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      list: Array
    }
  },
  created(){
    this.init()
  },
  methods:{
    init(){
      this.$axios.get('/show/scan?batchId=' + localStorage.getItem('batchId') ).then(res=>{
        this.list = res.data.retData.scanList
      })
    }
  }
}
</script>
<style scoped>
.body{
  background: #F8F8F8;
  height: 100vh;
}
.title{
  background: #F0F7FE;
  color: #999999;
  font-size: 10px;
  line-height: 30px;
  padding-left: 5px;
}
.list{
  display: flex;
  color: #666666;
  font-size: 12px;
  align-items: center;
  padding: 0 15px;
}
.index{
  width: 18px;
  height: 18px;
  background: #0775F1;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;
  font-size: 10px;
  line-height: 18px;
}
</style>

