<template>
  <div class="enterprise">
    <div class="title">{{list.merchantName}}</div>
    <div style="padding: 0 20px;">地址: {{list.address}}</div>
    <div class="but">资质信息</div>
    <p style="padding: 20px;">{{list.honor}}</p>
    <div v-for="(itme,index) in list.merchantInfo" :key="index">
      <div style="margin-left: 20px;">{{itme.title}}</div>
      <van-image width="100%" :src="itme.picUrl" style="margin-top: 10px"></van-image>
    </div>
    
  </div>
</template>
<script>
export default {
  props:{
    list:{
      type: Object,
      required: true
    }
  },
  data(){
    return{
    }
  },
  created(){
  },
  methods:{

  }
}
</script>
<style scoped>
.title{
    text-align: center;
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
}
.enterprise .but{
    margin-top: 20px;
    color: white;
    line-height: 3rem;
    padding-left: 20px;
    font-size: 1rem;
    height: 3rem;
    background-color: #005BAC;
}
</style>

