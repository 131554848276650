<template>
  <div class="productBody">
    <div><span class="title">产品品牌:</span><span class="conten">{{list.brandName}}</span></div>
    <div><span class="title">委托类型:</span><span class="conten">{{list.authType=='1'?'企业':'个人'}}</span></div>
    <div v-if="list.authType=='1'"><span class="title">委托企业:</span><span class="conten">{{list.enterprise}}</span></div>
    <div><span class="title">规格型号:</span><span class="conten">{{list.spec}}</span></div>
    <div><span class="title">原产国:</span><span class="conten">{{list.oldCountry}}</span></div>
    <div><span class="title">原厂货号:</span><span class="conten">{{list.itemNo}}</span></div>
    <div v-if="list.content && list.content !=''">
      <div><span class="content" v-html="list.content"></span></div>
    </div>
    <div v-else>
      <div v-if="list.color"><span class="title">产品颜色:</span><span class="conten">{{list.color}}</span></div>
      <div v-if="list.season"><span class="title">季节:</span><span class="conten">{{list.season}}</span></div>
      <div v-if="list.sex"><span class="title">性别:</span><span class="conten">{{list.sex}}</span></div>
      <div><span class="title">来样时间:</span><span class="conten">{{list.createDate}}</span></div>
      <div><span class="title">检验时间:</span><span class="conten">{{list.auditDate}}</span></div>
      <div><span class="title">检验机构:</span><span class="conten">{{jsonPrase(list.auditOrg)}}</span></div>
      <div><span class="title">{{getAudit(list.auditType)}}:</span> <span :class="['conten', list.resultType == 1 ? '' : list.resultType == 2?'textColor':'textColor1']">{{list.auditResult}}</span></div>
      <div><span class="title">备注:</span><span class="conten" v-html="replace(list.remark)"></span></div>
    </div>
    <div class="bottomBut">
      <van-button type="info" round style="width: 80%;" @click="$router.push({name: 'certificate',params:{data:list.traceList[0]}})">区块链证书</van-button>
    </div>
    
  </div>
</template>
<script>
export default {
  props:{
    list:{
      type: Object,
      required: true
    }
  },
  data(){
    return{
    }
  },
  methods:{
    replace(e){
      if(e){
        return e.replace(/。/g,"</br>")
      }
    },
    jsonPrase(str){
      const arr = JSON.parse(str)
      return arr.toString()
    },
    getAudit(type){
      if(type=='1'){
        return '检验结论'
      }
      if(type=='2'){
        return '检测结论'
      }
      if(type=='3'){
        return '合格评定结论'
      }
    }
  }
}
</script>
<style scoped>
.productBody{
    padding: 10px 20px;
    line-height: 26px;
    font-size: 0.8rem
}
.textColor{
  color: #ff0000;
}
.textColor1{
  color: #ffba00;
}
.productBody .title{
    color: #000;
    font-weight: 600
}
.productBody .conten{
    margin-left: 10px;
}
.bottomBut{
  text-align: center;
  margin-top: 20px;
}
</style>

