<template>
<div>
  
</div>
</template>
<script>
export default {
  data(){
    return{
      list: Array
    }
  },
  created(){
    this.init()
  },
  methods:{
    init(){
        
    const batchId = this.getQueryString('batchId')
      this.$axios.get('/show/getBusiType?batchId=' + batchId ).then(res=>{
       console.log(res)
       const busiType = res.data.retData.busiType
       if(busiType == '1'){
           this.$router.push({name: 'index'})
       }else{
           this.$router.push({name: 'product'})
       }
      })
    },
    getQueryString(name) {
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
            var r = window.location.search.substr(1).match(reg);
            if (r != null) {
                localStorage.setItem('batchId', unescape(r[2]));
                return unescape(r[2]);
            }
                return null;
        }
  }
}
</script>
<style scoped>

</style>

